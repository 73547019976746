import React from 'react';
import Homebar from '../components/Homebar';
import VideoPlayer from '../components/YoutubeVideo';
import InformationsModal from '../components/InformationsModal';
import { getDescription, getPictures, getVideos, getCgv, getLegalMentions, deletePictures } from '../routes/requests';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import AddImageModal from '../components/AddImageModal';
import FadeInText from '../components/FadeInText';
import Map from '../components/Map';
import Background from '../components/Background';
import CarouselSlider from '../components/Slider';

const Home = () => {
    const [cgv, setCgv] = React.useState("");
    const [legal, setLegal] = React.useState("");
    const [showModal, setShowModal] = React.useState("");

    const [description, setDescription] = React.useState("");
    
    const [pictures, setPictures] = React.useState([]);
    const [backgroundPicture, setBackgroundPicture] = React.useState("");

    const [videos, setVideos] = React.useState([]);

    const [hasCookie, setHasCookie] = React.useState(false);

    const navigate = useNavigate();

    const changePage = (page) => {
        navigate(page);
    };

    const toggleModal = () => {
        setShowModal("");
    };

    const openPictureAdd = () => {
        setShowModal("picture");
    };

    const openCgv = () => {
        setShowModal("cgv");
    }

    const openLegal = () => {
        setShowModal("legal");
    }

    const removePicture = (name) => {
        deletePictures(Cookies.get("login"), name).then((result) => {
            if (result) {
                window.location.reload();
            }
        })
    };

    React.useEffect(() => {
        const getDescriptionApi = async () => {
            const result = await getDescription();
            setDescription(result.data);
        };

        const getPicturesApi = async () => {
            const result = await getPictures();
            setBackgroundPicture(result.filter((elem) => elem.location === "accueil").at(0).file);
            setPictures([...result.filter((elem) => elem.location !== "accueil" && elem.location !== "shop")]);
        };

        const getVideosApi = async () => {
            const result = await getVideos();
            setVideos([...result]);
        };

        const getCgvLegal = async () => {
            let result = await getCgv();
            setCgv(result.content);
            result = await getLegalMentions();
            setLegal(result.content);
        }

        getDescriptionApi();
        getPicturesApi();
        getVideosApi();
        getCgvLegal();

        if (Cookies.get("login")) {
            setHasCookie(true);
        }
    // eslint-disable-next-line
    }, []);

    return (
        <Background>
            <Homebar />

            {/* Contenu de la page */}
            <div className="flex flex-col items-center justify-center">

                {/* Image accueil */}
                <div className='w-full bg-cover bg-center h-screen flex flex-col items-center justify-between' style={{
                    backgroundImage: `url("https://penacamargua.fr:8000/static/${backgroundPicture}")`,
                    height: 'calc(100vh - 56px)'
                }}>
                    <div>
                        <h1 
                            className="text-6xl font-bold text-center mb-8 text-mainBlue text-stroke"
                        >
                            Peña Camargua
                        </h1>
                    </div>
                    <div>
                        <button 
                            className='bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 hover:bg-secondBlue'
                            onClick={() => changePage("/boutique")}
                        >
                            Acheter notre album
                        </button>
                    </div>
                </div>

                <div className='w-full h-auto pb-fivePercent'>
                    <FadeInText>
                        <h1 className='text-4xl font-bold px-[5%] mt-4 text-white'>Notre objectif</h1>
                    </FadeInText>
                    <FadeInText>
                        <pre className='text-white mt-8 mx-auto px-[5%] whitespace-pre-wrap break-words'>
                            Animer vos journées/soirées, qu'il s'agisse d'évènements publics ou privés.
                        </pre>
                    </FadeInText>
                </div>

                <div className='w-full h-auto pb-fivePercent'>
                    <FadeInText>
                        <h1 className='text-4xl font-bold px-[5%] mt-4 text-white'>Composition de l'équipe</h1>
                    </FadeInText>
                    <FadeInText>
                        <pre className='text-white mt-8 mx-auto px-[5%] whitespace-pre-wrap break-words'>
                            <ul>
                                <li>Trompettes</li>
                                <li>Saxophones</li>
                                <li>Soubassophone</li>
                                <li>Trombone</li>
                                <li>Percussions sous forme de batterie complète ou décomposée en grosse caisse, caisse-claire, cymbales</li>
                            </ul>
                            <br/>
                            Notre formation peut être déambulatoire, ou sous forme d'orchestre selon le type d'événement.
                        </pre>
                    </FadeInText>
                </div>

                <div className='w-full h-auto pb-fivePercent'>
                    <FadeInText>
                        <h1 className='text-4xl font-bold px-[5%] mt-4 text-white'>Les prestations</h1>
                    </FadeInText>
                    <FadeInText>
                        <pre className='text-white mt-8 mx-auto px-[5%] whitespace-pre-wrap break-words'>
                            <ul>
                                <li>Fêtes votives</li>
                                <li>Prestations taurines</li>
                                <li>Anniversaires</li>
                                <li>Mariages</li>
                                <li>Baptêmes</li>
                            </ul>
                            <br/>
                            Mais aussi pour les comités d'entreprises, bars, restaurants, théâtres, discothèques, et bien plus !
                            <br/>
                            <br />
                            Nos registres musicaux s'inscrivent dans le Paso Doble, la variété française, le rock, les musiques d'ambiance ainsi que des valses, tangos,polkas...
                            <br />
                            Nous sommes à votre écoute et volontaires pour tout type de demande d'animations.
                            <br />
                            Les musiciens professionnels qui composent le groupe nous donnent la possibilité d'assurer nos prestations toute l'année et tous les jours dela semaine.
                        </pre>
                    </FadeInText>
                </div>

                <div className='w-full h-auto pb-tenPercent'>
                    <FadeInText>
                        <h1 className='text-4xl font-bold px-[5%] mt-4 text-white'>Intéressé.es ?</h1>
                    </FadeInText>
                    <FadeInText>
                        <pre className='text-white mt-8 mx-auto px-[5%] whitespace-pre-wrap break-words'>
                            Vous pouvez réserver vos dates à n'importe quel moment de l'année.
                            <br />
                            Il vous suffit d'appeler ou d'envoyer un mail afin de vous assurer de la disponibilité de la date.
                            <br />
                            Le tarif de la prestation est annoncé lors de votre demande de contrat 
                            et notre secrétaire se charge de vous établir un devis par mail qui sera validé après réception du devis signé.
                        </pre>
                    </FadeInText>
                </div>

                <div className='w-full h-auto pb-fivePercent'>
                    <h1 className='text-4xl font-bold px-[5%] mt-4 text-center text-white'>Où nous trouver ?</h1>
                    <Map />
                </div>

                <div className='w-full h-auto pb-fivePercent mt-20 overflow-hidden'>
                <h1 className='text-4xl font-bold text-center mb-3 text-white'>Photos</h1>
                    <CarouselSlider />
                </div>

                {/* Photos et vidéos */}
                <div className='w-full h-auto'>
                    <div className='mt-16'>
                        <h1 className='text-4xl font-bold text-center mb-3 text-white'>Mais surtout, écoutez-nous</h1>
                        {
                            videos.map((id, index) => {
                                return <VideoPlayer
                                    key={index}
                                    id_str={id}
                                />
                            })
                        }
                    </div>
                </div>
                {
                hasCookie ?
                <div className="flex justify-center">
                    <button 
                    className="bg-mainBlue text-white font-bold px-8 py-3 rounded-full mt-1 mb-3"
                    onClick={openPictureAdd}
                    >
                    Ajouter une photo
                    </button>
                </div> : <></>
            }
                <button 
                className='bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 hover:bg-secondBlue'
                onClick={openLegal}
            >
                Mentions légales
            </button>
            <button 
                className='bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-1 hover:bg-secondBlue'
                onClick={openCgv}
            >
                Conditions Générales de Vente
            </button>
            </div>
            {showModal === "cgv" && (
                        <InformationsModal 
                            onClose={toggleModal}
                            description={cgv}
                            title={"Conditions générales de vente"}
                        />
                    )}
                    {showModal === "legal" && (
                        <InformationsModal 
                            onClose={toggleModal}
                            description={legal}
                            title={"Mentions légales"}
                        />
                    )}
                    {showModal === "picture" && (
                        <AddImageModal 
                            onClose={toggleModal}
                        />
                    )

                    }
        </Background>
    );
};

export default Home;