import React from "react";
import Slider from "react-slick";

const CarouselSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
  };

  const slides = [
    { src: './images/1.jpeg', text: 'Prestations taurines' },
    { src: './images/2.jpg', text: 'Prestations taurines' },
    { src: './images/3.jpg', text: 'Fêtes de villages / Férias'},
    { src: './images/4.jpg', text: 'Fêtes de villages / Férias'},
    { src: './images/5.jpg', text: 'Fêtes de villages / Férias'},
    { src: './images/6.jpeg', text: 'Fêtes de villages / Férias'},
    { src: './images/7.jpeg', text: 'Fêtes de villages / Férias'},
    { src: './images/8.jpeg', text: 'Fêtes de villages / Férias'},
    { src: './images/9.JPG', text: 'Evènement divers'},
    { src: './images/10.JPG', text: 'Evènement divers'},
    { src: './images/11.jpeg', text: 'Mariages / Anniversaires'},
];

  return (
    <Slider {...settings} className="text-white relative">
      {slides.map((slide, key) => {
        return (
          <div key={key} className="flex justify-center">
            <div className="flex justify-center items-center w-full h-full relative">
              <img
                src={slide.src}
                className="w-[70%] h-[70vh] object-contain"
              />
              {/* Banderole centrée sur l'image */}
              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-60 text-center p-2">
                <h2 className="text-white font-bold">{slide.text}</h2>
              </div>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default CarouselSlider;
